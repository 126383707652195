import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { doc, deleteDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CircularProgress from '@mui/material/CircularProgress';
import { roundNoFixed } from 'components/utils';
import Papa from 'papaparse';
import SharedCertificateStatus from './SharedCertificateStatus';
import GauginCurve from './creation/GauginCurve';

export default function CertificateDetail({
  open, setOpen, certificate, certificateDeleteCallback,
}) {
  const { t } = useTranslation();
  const [openWarning, setopenWarning] = useState(false);
  const navigate = useNavigate();
  const [canUpdateInfo, setCanUpdateInfo] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setopenWarning(false);
  };

  const handleUpdateInfo = () => {
    navigate('updateInfo', {
      state: {
        certificate,
      },
    });
  };

  const handleUpdateGaugingData = () => {
    navigate('create', {
      state: {
        update: true,
        certificate,
      },
    });
  };

  const canDelete = () => {
    switch (certificate.status) {
      case 'refused':
        return true;
      case 'created':
        return true;
      default:
        return false;
    }
  };

  const statusesCallback = (statuses) => {
    setCanUpdateInfo(statuses.length === 0);
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {`${t('tank_selection.certificate')} : ${certificate.certificateName}`}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3, pb: 0,
      }}
      >
        <CertificateContent certificate={certificate} statusesCallback={statusesCallback} />
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          gap: 1,
        }}
        >
          <Button
            disabled={!canDelete()}
            color="error"
            variant="outlined"
            onClick={() => setopenWarning(true)}
          >
            {t('delete')}
          </Button>
          <Button disabled={!canUpdateInfo} variant="outlined" onClick={handleUpdateInfo}>
            {t('admin.update_info')}
          </Button>
          <Button variant="outlined" onClick={handleUpdateGaugingData}>
            {t('admin.fix_gauging')}
          </Button>
        </Box>

      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1 }}>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
      <CertificateWarningDelete
        certificateDeleteCallback={certificateDeleteCallback}
        certificate={certificate}
        openWarning={openWarning}
        handleClose={handleClose}
        setopenWarning={setopenWarning}
      />
    </Dialog>
  );
}

function CertificateContent(props) {
  const { t } = useTranslation();
  const { certificate, statusesCallback } = props;
  const navigate = useNavigate();

  const handleGoToPDFViewer = () => {
    navigate(`/pdfViewer?filePath=${encodeURIComponent('gs://labox-ws.appspot.com/organizations/'
      + `${certificate.gaugerOrganizationId}/apps/digitank-gauger/`
      + `gaugerCertificates/${certificate.certificateId}.pdf`)}`);
  };

  const containerLabel = () => (certificate.type === 'tanker_truck'
    ? t('tank_selection.tank') : t('tank_selection.container'));

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="body2">
            {`${t('owner')} : ${certificate.ownerOrganizationName}`}
            <br />
            {certificate.cellarName && (`${t('admin.cellar')} : ${certificate.cellarName}`)}
            <br />
            {`${containerLabel()} : ${certificate.tankName}`}
            <br />
            {`Type : ${t(`type.${certificate.type}`)}`}
            <br />
          </Typography>
          <SharedCertificateStatus certificate={certificate} statusesCallback={statusesCallback} />
        </Box>
        <Typography variant="body2">
          {`${t('valid_until')} : ${certificate.expirationDate.toDate().toLocaleDateString()}`}
          <br />
          {`${t('integration')} : ${certificate.integrationDate.toDate().toLocaleDateString()}`}
          <br />
          {`${t('last_modified')} : ${certificate.lastModified.toDate().toLocaleDateString()}`}
          <br />
          {`${t('version')} : ${certificate.version}`}
        </Typography>
      </Box>
      <Button
        onClick={handleGoToPDFViewer}
        size="small"
        variant="contained"
        color="primary"
        startIcon={<PictureAsPdfIcon />}
      >
        {t('storage.certificate_pdf')}
      </Button>
      <CertificateGaugingCurve certificate={certificate} />
    </Box>
  );
}

function CertificateWarningDelete(props) {
  const db = useFirestore();
  const { t } = useTranslation();
  const {
    handleClose, certificateDeleteCallback, openWarning, certificate, setopenWarning,
  } = props;

  const handleDelete = async () => {
    await deleteDoc(doc(
      db,
      `organizations/${certificate.gaugerOrganizationId}/apps/digitank-gauger/`
      + `gaugerCertificates/${certificate.certificateId}`,
    ));

    certificateDeleteCallback(certificate.certificateId);
    handleClose();
  };

  const handleThisClose = () => {
    setopenWarning(false);
  };

  return (
    <Dialog
      open={openWarning}
      onClose={handleThisClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <Typography variant="body2">{t('admin.certificate_warning_deletion')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleDelete}>
          {t('delete')}
        </Button>
        <Button onClick={handleThisClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CertificateGaugingCurve({ certificate }) {
  const [loading, setLoading] = useState(true);
  const [compartmentsDictionaries, setCompartmentsDictionaries] = useState([]);
  const [allHeights, setAllHeights] = useState([]);
  const [error, setError] = useState(null);

  const replaceComma = React.useCallback((value) => {
    if (value !== null) {
      return value.toString().replace(/,/, '.');
    }
    return null;
  }, []);

  const numberOrNull = React.useCallback((value) => {
    if (value === undefined || value === '' || value === null) {
      return null;
    }
    const number = Number(replaceComma(value));
    if (isNaN(number)) {
      return null;
    }
    return number;
  }, [replaceComma]);

  const toHL = React.useCallback((v) => roundNoFixed(v * 10, 3), []);

  const toMM = React.useCallback((v) => roundNoFixed(v * 1000, 10), []);

  React.useEffect(() => {
    if (!certificate || !certificate.csvCertificateContent) {
      setError('No certificate data available');
      setLoading(false);
      return;
    }

    try {
      const parsedCsv = Papa.parse(certificate.csvCertificateContent, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        delimitersToGuess: [';', ','],
      });

      const headers = parsedCsv.meta.fields;
      const heightHeader = 'hauteur';
      const compartmentIds = headers.filter(
        (x) => x !== '' && x !== heightHeader,
      );

      const newCompartmentsDictionaries = compartmentIds.map((id) => ({
        id,
        minVolume: 9999999,
        minHeight: 9999999,
        maxVolume: 0,
        maxHeight: 0,
        heightToVolume: {},
      }));

      const newAllHeights = [];

      for (const values of parsedCsv.data) {
        let height = numberOrNull(values[heightHeader]);
        height = toMM(height);
        newAllHeights.push(height);
        for (const compartment of newCompartmentsDictionaries) {
          let compartmentVolume = numberOrNull(values[compartment.id]);
          if (compartmentVolume !== null) {
            compartmentVolume = toHL(compartmentVolume);
            if (compartmentVolume > compartment.maxVolume) {
              compartment.maxVolume = compartmentVolume;
            }
            if (compartmentVolume < compartment.minVolume) {
              compartment.minVolume = compartmentVolume;
            }
            if (height > compartment.maxHeight) {
              compartment.maxHeight = height;
            }
            if (height < compartment.minHeight) {
              compartment.minHeight = height;
            }
            compartment.heightToVolume[height] = compartmentVolume;
          }
        }
      }

      setCompartmentsDictionaries(newCompartmentsDictionaries);
      setAllHeights(newAllHeights);
      setLoading(false);
    } catch (err) {
      console.error('Error parsing CSV data:', err);
      setError('Error parsing certificate data');
      setLoading(false);
    }
  }, [certificate, numberOrNull, toHL, toMM]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="body2" sx={{ p: 1 }}>
        {error}
      </Typography>
    );
  }

  if (compartmentsDictionaries.length === 0) {
    return (
      <Typography variant="body2" sx={{ p: 1 }}>
        Aucune donnée disponible
      </Typography>
    );
  }

  return (

    <GauginCurve
      compartmentsDictionaries={compartmentsDictionaries}
      allHeights={allHeights}
    />

  );
}
