import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useUser, useFunctions } from 'reactfire';
import { useFormik } from 'formik';
import { httpsCallable } from 'firebase/functions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useGlobal from 'global-state/store';
import { useNavigate } from 'react-router-dom';
import { roundNoFixed } from 'components/utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import CertificateCertificateDropZone from './CertificateDropZone';
import CertificateInfoDropZone from './CertificateInfoDropZone';
import CertificateGaugingDataDropZone from './CertificateGaugingDataDropZone';
import NominalVolumesList from './NominalVolumesList';
import CertificateInfoInputs from './CertificateInfoInputs';
import SuccessDialog from './SuccessDialog';
import GaugingDataValidation from './GaugingDataValidation';
import validationSchema from './validationSchema';

export default function CertificateCreationForm({
  type, update, gaugerOrganizationId, gaugerOrganizationName, existingCertificate,
}) {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [successOpen, setSuccessOpen] = useState(false);
  const [openWarningProceed, setopenWarningProceed] = useState(false);
  const [contentWarningProceed, setcontentWarningProceed] = useState(undefined);
  const [warningProceedCallback, setwarningProceedCallback] = useState(
    () => {},
  );
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const gaugerAdminCreateOrUpdateCertificateCall = httpsCallable(
    functions,
    'gaugerAdminCreateOrUpdateCertificateCall2ndGen',
  );
  const [uploading, setuploading] = useState(false);
  const navigate = useNavigate();
  const acceptedHeightUnits = ['m'];
  const acceptedVolumeUnits = ['m3'];

  const generateInitialValues = () => {
    const defaultValues = {
      certificateId: update
        ? existingCertificate.certificateId : uuidv4(),
      certificateName: update
        ? existingCertificate.certificateName : '',
      type: update
        ? existingCertificate.type : type,
      ownerOrganizationName: update
        ? existingCertificate.ownerOrganizationName
        : '',
      gaugerOrganizationId,
      gaugerOrganizationName,
      gaugingProviderName: '',
      tankId: update ? existingCertificate.tankId : uuidv4(),
      tankName: update ? existingCertificate.tankName : '',
      cellarName: update && existingCertificate.cellarName ? existingCertificate.cellarName : '',
      expirationDate: update ? moment.unix(existingCertificate.expirationDate.seconds) : null,
      gaugeDate: update ? moment.unix(existingCertificate.gaugeDate.seconds) : null,
      heightUnit: 'm',
      volumeUnit: 'm3',
      capacity: update ? existingCertificate.capacity : '',
      nominalVolumes: update && existingCertificate.type === 'gauged_container'
        ? roundNoFixed(existingCertificate.compartment.nominalVolume / 1000, 3) : '',
      compartmentsData: '',
      originalPdfCertificate: {},
      infoFileName: '...',
      dataFileName: '...',
      pdfFileName: '...',
    };
    if (type === 'tanker_truck') {
      const tankerTruckExtras = {
        nominalVolumesFromInfo: {},
        nominalVolumesNamesFromData: [],
        nominalVolumes: {},
      };

      return { ...defaultValues, ...tankerTruckExtras };
    }
    return defaultValues;
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setopenWarningProceed(false);
    if (update) {
      navigate(-1);
    } else {
      formik.resetForm({ values: generateInitialValues() });
      window.scrollTo(0, 0);
    }
  };

  const handleWarningProceed = (content, callback) => {
    setcontentWarningProceed(content);
    setwarningProceedCallback(() => callback);
    setopenWarningProceed(true);
  };

  const formik = useFormik({
    initialValues: generateInitialValues(),
    validationSchema: validationSchema(t),
    onSubmit(values) {
      return submit(values);
    },
  });

  async function upload(values) {
    try {
      setuploading(true);
      logEvent(analytics, 'admin_add_certificate_call', {
        user_uid: user?.uid,
        appName: 'Digitank, Gauger',
        organization: gaugerOrganizationId,
      });
      const valuesToSend = {
        ...values,
        operation: update === true ? 'update' : 'create',
        expirationDate: values.expirationDate.toDate(),
        gaugeDate: values.gaugeDate.toDate(),
      };
      await gaugerAdminCreateOrUpdateCertificateCall(valuesToSend);
      setSuccessOpen(true);
    } catch (error) {
      if (error.message === 'INTERNAL') {
        globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
      } else {
        globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
      }
      logEvent(analytics, 'error_admin_add_certificate_call', {
        user_uid: user?.uid,
        error_message: error.message,
        appName: 'Digitank, Gauger',
        organization: gaugerOrganizationId,
      });
    } finally {
      setuploading(false);
    }
  }

  async function compartmentsReviewAndUpload(values) {
    const compartmentsReviewContent = (
      <GaugingDataValidation
        certificateName={formik.values.certificateName}
        data={formik.values.compartmentsData}
      />
    );
    handleWarningProceed(compartmentsReviewContent, async () => upload(values));
  }

  async function checkCertificateIdAndUpload(values) {
    setopenWarningProceed(false);
    // Gotta fix that, the backend returns a readible proper error but we might avoid the call already.
    // Gotta double check if worth it
    const docExistsAlready = false;
    if (docExistsAlready && !update) {
      globalActions.setSnackbarMessage({
        message: t('certificate_creation.certificate_already_exists'),
        severity: 'error',
      });
    } else {
      await compartmentsReviewAndUpload(values);
    }
  }

  async function submit(values) {
    const missingThingsMessages = [];
    if (type === 'tanker_truck' && areMissingNominalVolumes(values.nominalVolumes)) {
      missingThingsMessages.push(
        t('certificate_creation.warning_missing_nominal_volumes'),
      );
    } else if (type === 'gauged_container' && values.nominalVolumes === '') {
      missingThingsMessages.push(
        t('certificate_creation.warning_missing_nominal_volumes'),
      );
    }
    if (isMissingPdf(values.originalPdfCertificate)) {
      missingThingsMessages.push(t('certificate_creation.warning_missing_pdf'));
    }
    if (missingThingsMessages.length > 0) {
      missingThingsMessages.push(t('certificate_creation.warning_continue'));
      const message = TypoComponent(missingThingsMessages.join('\n\n'));
      handleWarningProceed(message, async () => {
        await checkCertificateIdAndUpload(values);
      });
    } else {
      await checkCertificateIdAndUpload(values);
    }
  }

  function areMissingNominalVolumes(nominalVolumes) {
    return Object.values(nominalVolumes).some(
      (value) => value === '' || value === undefined,
    );
  }

  function isMissingPdf(originalPdf) {
    return (
      originalPdf === undefined || Object.entries(originalPdf).length === 0
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 20,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mt: 2 }}>
        {`${t(`type.${formik.values.type}`)} : ${t('certificate_creation.add_button')}`}
      </Typography>
      <CertificateInfoDropZone
        type={formik.values.type}
        update={update}
        formik={formik}
        acceptedHeightUnits={acceptedHeightUnits}
        acceptedVolumeUnits={acceptedVolumeUnits}
      />

      <CertificateInfoInputs
        type={formik.values.type}
        formik={formik}
        acceptedHeightUnits={acceptedHeightUnits}
        acceptedVolumeUnits={acceptedVolumeUnits}
        context={update ? 'updateGaugingData' : 'creation'}
      />

      <CertificateGaugingDataDropZone type={formik.values.type} formik={formik} />
      {type === 'tanker_truck' && (
        <NominalVolumesList type={formik.values.type} formik={formik} />
      )}
      <CertificateCertificateDropZone type={formik.values.type} formik={formik} />
      <Button
        sx={{
          maxWidth: 200, alignSelf: 'center', mt: 5, mb: 5,
        }}
        variant="contained"
        type="submit"
        size="large"
        disabled={formik.isSubmitting || !formik.isValid}
        endIcon={<CloudUpload />}
      >
        {t('certificate_creation.upload_csv')}
      </Button>
      <SuccessDialog
        successOpen={successOpen}
        handleSuccessClose={handleSuccessClose}
      />
      <WarningProceedDialog
        openWarningProceed={openWarningProceed}
        setopenWarningProceed={setopenWarningProceed}
        contentWarningProceed={contentWarningProceed}
        warningProceedCallback={warningProceedCallback}
        uploading={uploading}
      />
    </form>
  );
}

function WarningProceedDialog(props) {
  const { t } = useTranslation();

  const {
    openWarningProceed,
    setopenWarningProceed,
    contentWarningProceed,
    warningProceedCallback,
    uploading,
  } = props;

  const handleClose = () => {
    if (!uploading) {
      setopenWarningProceed(false);
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openWarningProceed}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{contentWarningProceed}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('no')}
        </Button>
        <LoadingButton loading={uploading} variant="outlined" onClick={warningProceedCallback}>
          {t('yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function TypoComponent(message) {
  return (
    <Typography component="p" sx={{ whiteSpace: 'pre-line' }}>
      {message}
    </Typography>
  );
}
